<template>
  <!-- Page header -->
  <div class="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-7 col-lg-7 col-md-12">
          <div>
            <h1 class="text-white display-4 fw-semi-bold">{{ course.name }}</h1>
            <p class="text-white lead" v-html="course.welcome"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page content -->
  <div class="pb-10">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
          <!-- Card -->
          <div class="card rounded-3">
            <!-- Card header -->
            <div class="card-header border-bottom-0 p-0">
              <div>
                <!-- Nav -->
                <ul class="nav nav-lb-tab" id="tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="table-tab" data-bs-toggle="pill" href="#table" role="tab" aria-controls="table" aria-selected="true">Nội dung</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="description-tab" data-bs-toggle="pill" href="#description" role="tab" aria-controls="description" aria-selected="false">Giới thiệu</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="benefit-tab" data-bs-toggle="pill" href="#benefit" role="tab" aria-controls="benefit" aria-selected="false">Lợi ích</a>
                  </li>
                  <li class="nav-item" v-if="false">
                    <a class="nav-link" id="review-tab" data-bs-toggle="pill" href="#review" role="tab" aria-controls="review" aria-selected="false">Reviews</a>
                  </li>
                  <li class="nav-item" v-if="false">
                    <a class="nav-link" id="faq-tab" data-bs-toggle="pill" href="#faq" role="tab" aria-controls="faq" aria-selected="false">FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="tab-content" id="tabContent">
                <div class="tab-pane fade show active" id="table" role="tabpanel" aria-labelledby="table-tab">
                  <!-- Card -->
                  <div class="accordion" id="courseAccordion">
                    <div>
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li :class="index === 0 ? 'list-group-item px-0 pt-0' : 'list-group-item px-0'" v-for="(section, index) in course.sections" :key="'section' + index">
                          <!-- Toggle -->
                          <a :class="index === 0 ? 'h4 mb-0 d-flex align-items-center text-inherit text-decoration-none' : 'h4 mb-0 d-flex align-items-center text-inherit text-decoration-none active'" data-bs-toggle="collapse" :href="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
                            <div class="me-auto">
                              {{ section.name }}
                            </div>
                            <!-- Chevron -->
                            <span class="chevron-arrow ms-4">
                              <i :class="index === 0 ? 'fe fe-chevron-down fs-4' : 'fe fe-chevron-up fs-4'"></i>
                            </span>
                          </a>
                          <!-- Row -->
                          <!-- Collapse -->
                          <div :class="index === 0 ? 'collapse show' : 'collapse'" :id="'collapse' + index" data-bs-parent="#courseAccordion">
                            <div class="pt-3 pb-2">
                              <a v-on:click="checkViewCourse" href="javascript:void(0)" class="mb-2 d-flex justify-content-between align-items-center text-inherit text-decoration-none" v-for="(lesson, index) in section.lessons" :key="'lesson' + index">
                                <div class="text-truncate">
                                                                    <span class="icon-shape bg-light text-primary icon-sm rounded-circle me-2"><i
                                                                        class="mdi mdi-play fs-4"></i></span>
                                  <span>{{ lesson.name }}</span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="description" role="tabpanel" aria-labelledby="description-tab">
                  <div v-html="course.intro"></div>
                </div>
                <div class="tab-pane fade" id="benefit" role="tabpanel" aria-labelledby="benefit-tab">
                  <div v-html="course.benefit"></div>
                </div>
                <div v-if="false" class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                  <!-- Reviews -->
                  <div class="mb-3">
                    <h3 class="mb-4">How students rated this courses</h3>
                    <div class="row align-items-center">
                      <div class="col-auto text-center">
                        <h3 class="display-2 fw-bold">4.5</h3>
                        <i class="mdi mdi-star me-n1 text-warning"></i>
                        <i class="mdi mdi-star me-n1 text-warning"></i>
                        <i class="mdi mdi-star me-n1 text-warning"></i>
                        <i class="mdi mdi-star me-n1 text-warning"></i>
                        <i class="mdi mdi-star me-n1-half text-warning"></i>
                        <p class="mb-0 fs-6">(Based on 27 reviews)</p>
                      </div>
                      <!-- Progress bar -->
                      <div class="col pt-3 order-3 order-md-2">
                        <div class="progress mb-3" style="height: 6px;">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 90%;" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mb-3" style="height: 6px;">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mb-3" style="height: 6px;">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mb-3" style="height: 6px;">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mb-0" style="height: 6px;">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <div class="col-md-auto col-6 order-2 order-md-3">
                        <!-- Rating -->
                        <div>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <span class="ms-1">53%</span>
                        </div>
                        <div>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <span class="ms-1">36%</span>
                        </div>
                        <div>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <span class="ms-1">9%</span>
                        </div>
                        <div>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <span class="ms-1">3%</span>
                        </div>
                        <div>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <i class="mdi mdi-star me-n1 text-light"></i>
                          <span class="ms-1">2%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- hr -->
                  <hr class="my-5" />
                  <div class="mb-3">
                    <div class="d-lg-flex align-items-center justify-content-between mb-5">
                      <!-- Reviews -->
                      <div class="mb-3 mb-lg-0">
                        <h3 class="mb-0">Reviews</h3>
                      </div>
                      <div>
                        <!-- Form -->
                        <form class="form-inline">
                          <div class="d-flex align-items-center me-2">
                                                        <span class="position-absolute ps-3">
                              <i class="fe fe-search"></i>
                            </span>
                            <input type="search" class="form-control ps-6" placeholder="Search Review" />
                          </div>
                        </form>
                      </div>
                    </div>
                    <!-- Rating -->
                    <div class="d-flex border-bottom pb-4 mb-4">
                      <img src="@/../public/assets/images/avatar/avatar-2.jpg" alt="" class="rounded-circle avatar-lg" />
                      <div class=" ms-3">
                        <h4 class="mb-1">
                          Max Hawkins
                          <span class="ms-1 fs-6 text-muted">2 Days ago</span>
                        </h4>
                        <div class="fs-6 mb-2">
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                        </div>
                        <p>Lectures were at a really good pace and I never felt lost. The instructor was well informed and allowed me to learn and navigate Figma easily.</p>
                        <div class="d-lg-flex">
                          <p class="mb-0">Was this review helpful?</p>
                          <a href="#" class="btn btn-xs btn-primary ms-lg-3">Yes</a>
                          <a href="#" class="btn btn-xs btn-outline-white ms-1">No</a>
                        </div>
                      </div>
                    </div>
                    <!-- Rating -->
                    <div class="d-flex border-bottom pb-4 mb-4">
                      <img src="@/../public/assets/images/avatar/avatar-3.jpg" alt="" class="rounded-circle avatar-lg" />
                      <div class=" ms-3">
                        <h4 class="mb-1">Arthur Williamson <span class="ms-1 fs-6 text-muted">3 Days ago</span>
                        </h4>
                        <div class="fs-6 mb-2">
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                        </div>
                        <p>Its pretty good.Just a reminder that there are also students with Windows, meaning Figma its a bit different of yours. Thank you!</p>
                        <div class="d-lg-flex">
                          <p class="mb-0">Was this review helpful?</p>
                          <a href="#" class="btn btn-xs btn-primary ms-lg-3">Yes</a>
                          <a href="#" class="btn btn-xs btn-outline-white ms-1">No</a>
                        </div>
                      </div>
                    </div>
                    <!-- Rating -->
                    <div class="d-flex border-bottom pb-4 mb-4">
                      <img src="@/../public/assets/images/avatar/avatar-4.jpg" alt="" class="rounded-circle avatar-lg" />
                      <div class=" ms-3">
                        <h4 class="mb-1">Claire Jones <span class="ms-1 fs-6 text-muted">4 Days ago</span></h4>
                        <div class="fs-6 mb-2">
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                        </div>
                        <p>
                          Great course for learning Figma, the only bad detail would be that some icons are not included in the assets. But 90% of the icons needed are included, and the voice of the instructor was very clear and easy to understood.
                        </p>
                        <div class="d-lg-flex">
                          <p class="mb-0">Was this review helpful?</p>
                          <a href="#" class="btn btn-xs btn-primary ms-lg-3">Yes</a>
                          <a href="#" class="btn btn-xs btn-outline-white ms-1">No</a>
                        </div>
                      </div>
                    </div>
                    <!-- Rating -->
                    <div class="d-flex">
                      <img src="@/../public/assets/images/avatar/avatar-5.jpg" alt="" class="rounded-circle avatar-lg" />
                      <div class=" ms-3">
                        <h4 class="mb-1">
                          Bessie Pena
                          <span class="ms-1 fs-6 text-muted">5 Days ago</span>
                        </h4>
                        <div class="fs-6 mb-2">
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                          <i class="mdi mdi-star me-n1 text-warning"></i>
                        </div>
                        <p>
                          I have really enjoyed this class and learned a lot, found it very inspiring and helpful, thank you!
                          <i class="em em-heart_eyes ms-2 fs-6"></i>
                        </p>
                        <div class="d-lg-flex">
                          <p class="mb-0">Was this review helpful?</p>
                          <a href="#" class="btn btn-xs btn-primary ms-lg-3">Yes</a>
                          <a href="#" class="btn btn-xs btn-outline-white ms-1">No</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="false" class="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                  <!-- FAQ -->
                  <div>
                    <h3 class="mb-3">Course - Frequently Asked Questions</h3>
                    <div class="mb-4">
                      <h4>How this course help me to design layout?</h4>
                      <p>
                        My name is Jason Woo and I work as human duct tape at Gatsby, that means that I do a lot of different things. Everything from dev roll to writing content to writing code. And I used to work as an architect at IBM. I live in Portland, Oregon.
                      </p>
                    </div>
                    <div class="mb-4">
                      <h4>What is important of this course?</h4>
                      <p>
                        We'll dive into GraphQL, the fundamentals of GraphQL. We're only gonna use the pieces of it that we need to build in Gatsby. We're not gonna be doing a deep dive into what GraphQL is or the language specifics. We're also gonna get into MDX. MDX is a way
                        to write React components in your markdown.
                      </p>
                    </div>
                    <div class="mb-4">
                      <h4>Why Take This Course?</h4>
                      <p>
                        We'll dive into GraphQL, the fundamentals of GraphQL. We're only gonna use the pieces of it that we need to build in Gatsby. We're not gonna be doing a deep dive into what GraphQL is or the language specifics. We're also gonna get into MDX. MDX is a way
                        to write React components in your markdown.
                      </p>
                    </div>
                    <div class="mb-4">
                      <h4>Is able to create application after this course?</h4>
                      <p>
                        We'll dive into GraphQL, the fundamentals of GraphQL. We're only gonna use the pieces of it that we need to build in Gatsby. We're not gonna be doing a deep dive into what GraphQL is or the language specifics. We're also gonna get into MDX. MDX is a way
                        to write React components in your markdown.
                      </p>
                      <p>
                        We'll dive into GraphQL, the fundamentals of GraphQL. We're only gonna use the pieces of it that we need to build in Gatsby. We're not gonna be doing a deep dive into what GraphQL is or the language specifics. We're also gonna get into MDX. MDX is a way
                        to write React components in your markdown.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12 mt-lg-n22">
          <div class="card mb-3 mb-4">
            <div class="p-1">
              <video controls v-if="course.thumbnail && course.thumbnail.media_type === 'video'">
                <source :src="course.thumbnail.link" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <div class="d-flex justify-content-center position-relative rounded py-10 border-white border rounded-3 bg-cover" :style="'background-image: url(' + course.thumbnail.link + ');'" v-if="course.thumbnail && course.thumbnail.media_type === 'image'"></div>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <span class="text-dark fw-bold h2">Miễn phí</span>
              </div>
              <div class="d-grid">
                <button v-if="!myCourse" v-on:click="joinCourse" class="btn btn-primary mb-2">Tham gia khóa học</button>
                <router-link v-if="myCourse" class="btn btn-primary mb-2" :to="{name: 'ViewCourse', params: {code: course.code}}">Vào khóa học</router-link>
                <router-link v-if="course.combos && course.combos.data && course.combos.data[0]" class="btn btn-secondary mb-2" :to="{name: 'ComboDetail', params: {code: course.combos.data[0].code}}">Quay lại combo</router-link>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div>
              <div class="card-header">
                <h4 class="mb-0">Thông tin khóa học</h4>
                <div class="mt-4" v-if="myCourse && myCourse.userCoursePivot">
                  <div class="progress" style="height: 6px;">
                    <div class="progress-bar bg-success" role="progressbar" :style="'width: ' + myCourse.userCoursePivot.percent + '%;'" :aria-valuenow="myCourse.userCoursePivot.percent" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <small>{{ myCourse.userCoursePivot.percent }}% hoàn thành</small>
                </div>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item bg-transparent"><i class="fe fe-calendar align-middle me-2 text-info"></i>{{ course.section_count }} chương</li>
                <li class="list-group-item bg-transparent"><i class="fe fe-play-circle align-middle me-2 text-primary"></i>{{ course.lesson_count }} bài học</li>
                <li class="list-group-item bg-transparent"><i class="fe fe-user align-middle me-2 text-primary"></i>{{ course.users.paginatorInfo.total }} học viên tham gia</li>
              </ul>
            </div>
          </div>

          <div class="card" v-if="course.teachers && course.teachers.length">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="position-relative">
                  <img :src="course.teachers[0].thumbnail ? course.teachers[0].thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="rounded-circle avatar-xl" />
                  <a href="#" class="position-absolute mt-2 ms-n3" data-bs-toggle="tooltip" data-placement="top" title="Verifed">
                    <img src="@/../public/assets/images/svg/checked-mark.svg" alt="" height="30" width="30" />
                  </a>
                </div>
                <div class="ms-4">
                  <h4 class="mb-0">{{ course.teachers[0].name }}</h4>
                  <p class="mb-1 fs-6">Giáo viên chuyên môn</p>
                  <span class="fs-6"><span class="text-warning">4.5</span><span class="mdi mdi-star text-warning me-2"></span>đánh giá</span>
                </div>
              </div>
              <div class="border-top row mt-3 border-bottom mb-3 g-0">
                <div class="col">
                  <div class="pe-1 ps-2 py-3">
                    <h5 class="mb-0">11,604</h5>
                    <span>học viên</span>
                  </div>
                </div>
                <div class="col border-start">
                  <div class="pe-1 ps-3 py-3">
                    <h5 class="mb-0">32</h5>
                    <span>khóa học</span>
                  </div>
                </div>
                <div class="col border-start">
                  <div class="pe-1 ps-3 py-3">
                    <h5 class="mb-0">12,230</h5>
                    <span>đánh giá</span>
                  </div>
                </div>
              </div>
              <p>I am an Innovation designer focussing on UX/UI based in Berlin. As a creative resident at Figma explored the city of the future and how new technologies.</p>
              <a href="instructor-profile.html" class="btn btn-outline-white btn-sm">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="joinCourseModal" title="Tham gia khóa học" :message="joinCourseModalMessage" :type="joinCourseModalType" />

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" title=""
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Modal from "@/components/Modal";
import {getUser} from "@/core/services/jwt.service";

export default {
  name: 'CourseDetail',
  components: {
    Modal
  },
  data() {
    return {
      course: {
        name: "",
        intro: "",
        welcome: "",
        benefit: "",
        fee_type: 0,
        sections: [],
        teachers: [],
        users: {
          paginatorInfo: {
            total: 0
          }
        }
      },
      myCourse: null,
      joinCourseModalMessage: "",
      joinCourseModalType: "",
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: ""
    }
  },
  methods: {
    loadCourse() {
      let query = `query {
        course(code:"${this.$route.params.code}") {
          id
          code
          name
          intro
          welcome
          benefit
          fee_type
          lesson_count
          section_count
          sections(orderBy: [{column: "position", order: ASC}]) {
            name
            lessons(orderBy: [{column: "position", order: ASC}]) {
              name
              content
            }
          }
          teachers {
            name
            thumbnail {
              link
              media_type
            }
          },
          users(first: 1) {
            paginatorInfo {
              total
            }
          }
          thumbnail {
            link
            media_type
          }
          combos(first: 1) {
            data {
              code
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.course) {
              this.course = data.data.course;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadMyCourse() {
      let user = getUser();
      let user_id = user ? parseInt(user.id) : 0;

      let query = `query {
        course(code:"${this.$route.params.code}") {
          users(first: 1, where: {AND: [{column: "users.id", value: ${user_id}}]}) {
            data {
              name
              uid
              userCoursePivot {
                percent
              }
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.course && data.data.course.users && data.data.course.users.data && data.data.course.users.data.length) {
              this.myCourse = data.data.course.users.data[0];
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    joinCourse() {
      this.checkIfUserNotLoggedIn();

      let query = `mutation {
        joinCourse(course_code: "${this.course.code}")
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.joinCourse) {
              this.joinCourseModalMessage = "Xin chúc mừng, bạn đã tham gia khóa học thành công!";
              this.joinCourseModalType = "success";
              window.$("#joinCourseModalOpen").click();
              this.$router.push({name: "ViewCourse", params: {code: this.course.code}});
            } else {
              if (data.errors) {
                this.joinCourseModalMessage = data.errors[0].message;
                this.joinCourseModalType = "danger";
                window.$("#joinCourseModalOpen").click();
              }
            }
          })
          .catch((response) => {
            this.joinCourseModalMessage = response.message;
            this.joinCourseModalType = "danger";
            window.$("#joinCourseModalOpen").click();
          });
    },
    checkIfUserNotLoggedIn() {
      let user = getUser();
      if (!user) {
        this.$router.push({name: "Login"});
      }
    },
    checkViewCourse() {
      if (this.myCourse) {
        this.$router.push({name: "ViewCourse", params: {code: this.course.code}});
      } else {
        alert('Bạn phải tham gia khóa học để thực hiện chức năng này');
      }
    }
  },
  mounted() {
    this.loadCourse();
    this.loadMyCourse();
  },
  computed: {

  }
}
</script>
